import React, { useEffect, useState } from 'react'

import { graphql, Link } from 'gatsby'

import Img from 'gatsby-image'

import Layout from '../components/layout'

import '../styles/layout-styles.css'
import '../styles/home-styles.css'
import '../styles/common-styles.css'
import '../styles/event-styles.css'

const EventsPage = ({ data }) => {
  const [posts, setPosts] = useState([])

  useEffect(() => {
    const posts = data.allMarkdownRemark.nodes

    const blogElements = []

    posts.forEach((post) => {
      blogElements.push(
        <div className='blogItem'>
          <span className='text-nittanyNavy my-1 text-xl md:text-3xl'>
            {post.frontmatter.title}
          </span>
          <span className='blogDate'>{post.frontmatter.date}</span>
          <span className='blogDate' style={{ marginBottom: '25px' }}>
            {post.frontmatter.time}
          </span>
          <div className='eventImage'>
            <Img fluid={post.frontmatter.image.childImageSharp.fluid} />
          </div>
          <p className='blogDate'>{post.frontmatter.description}</p>
          {!post.frontmatter.useLink && (
            <Link className='blogLink' to={post.frontmatter.slug}>
              {post.frontmatter.linkTitle}
            </Link>
          )}
          {post.frontmatter.useLink && (
            <a
              className='blogLink'
              href={post.frontmatter.link}
              target='_blank'
              rel='noreferrer'
            >
              {post.frontmatter.linkTitle}
            </a>
          )}
        </div>
      )
    })
    setPosts(blogElements)
  }, [data])

  return (
    <Layout>
      <div className='text-3xl my-6 text-nittanyNavy text-center md:my-14 lg:text-6xl'>
        Events
      </div>
      <div className='text-primary mx-5 md:mx-auto md:max-w-xl lg:max-w-4xl xl:max-w-5xl'>
        {posts}
      </div>
    </Layout>
  )
}

export default EventsPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        frontmatter {
          slug
          date(formatString: "MMMM DD, YYYY")
          time
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 700, maxHeight: 400) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          link
          useLink
          linkTitle
          showOnHomepage
        }
      }
    }
  }
`
